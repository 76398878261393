const getPathType = (route: string) => {
  const routes = route.split('/')

  if (routes[1] === '' || routes[1] === 'search') {
    return 'home'
  } else if (routes[1] === 'general') {
    return 'general'
  } else {
    return ''
  }
}

export default getPathType
