import '../styles/globals.css'
import 'nprogress/nprogress.css'
import type { AppProps } from 'next/app'
import { Router } from 'next/router'
import Head from 'next/head'
import tw, { GlobalStyles } from 'twin.macro'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
const NProgress = require('nprogress')

//Binding events.
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const Main = tw.div`
      h-screen
`

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Meerkt Blog</title>
        <link rel="icon" type="image/x-icon" href={'/favicon.ico'} />
        <link rel="shortcut icon" type="image/x-icon" href={'/favicon.ico'} />
        <script async data-api={'/_hive'} src={'/bee.js'} />
      </Head>
      <GlobalStyles />
      <Main>
        <Navbar />
        <Component {...pageProps} />
        <Footer />
      </Main>
    </>
  )
}
export default MyApp
