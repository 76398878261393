import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import getPathType from '../utils/getPathType'
import Image from 'next/image'
import tw, { styled } from 'twin.macro'

import AppIcon from '../public/appicon.png'

const Nav = tw.nav`
      border-b
      fixed
      w-screen
      bg-white
      bg-opacity-95
      z-20
`

const TopContainer = tw.div`
      grid 
      grid-cols-4 
      justify-start 
      max-w-7xl 
      mx-auto 
      px-2 
      sm:px-6 
      lg:px-8 
      mt-2
`

const LogoContainer = tw.div`
      flex-shrink-0 
      flex 
      items-center 
      mr-2 
      col-span-4
`

const Logo = tw.div`
      block 
      h-8 
      w-auto
      mx-2 
      my-2 
      cursor-pointer
`

const TabList = tw.div`
      max-w-7xl 
      mx-auto 
      px-2 
      sm:px-6 
      lg:px-8 
      flex 
      overflow-x-auto 
      pt-2
`

const Tab = styled.a`
  ${({ active }: { active: boolean }) =>
    active ? tw`border-b border-yellow-500` : ''}

  ${tw`text-black 
      px-3 
      py-2 
      rounded-md 
      text-sm 
      font-medium`}
`

const Navbar = () => {
  const router = useRouter()
  const [currentTab, setCurrentTab] = useState<string>('general')

  useEffect(() => {
    setCurrentTab(getPathType(router.route))
  }, [router])

  return (
    <Nav>
      <TopContainer>
        <LogoContainer>
          <Link href={'/'} passHref>
            <Logo>
              <Image src={AppIcon} alt="Meerkt" width={32} height={32} />
            </Logo>
          </Link>
          <Link href={'/'} passHref>
            <a className={'text-xl my-auto font-medium border-l px-2'}>
              Official Meerkt Blog
            </a>
          </Link>
        </LogoContainer>
      </TopContainer>
      <TabList>
        <Link href={'/'} passHref>
          <Tab active={currentTab === 'home'}>Home</Tab>
        </Link>
        <Link href={'/general'} passHref>
          <Tab active={currentTab === 'general'}>General</Tab>
        </Link>
      </TabList>
    </Nav>
  )
}

export default Navbar
